.contact {
  min-height: 100vh;
  display: flex;
  align-items: center;

  background: linear-gradient(rgba(0, 47, 75, 0.6), rgba(220, 66, 37, 0.6));

  .title {
    padding-bottom: 3rem;
  }

  a {
    color: black;
    font-size: 3rem;

    &:hover {
      color: white;
    }
  }
}