.home-slides {
  color: aliceblue;
  padding-top: 25vh;

  &.summary-slide {
    padding-top: 30vh;
  }

  &.software-eng-slide {
    padding-top: 15vh;
  }

  &.product-manager-slide {
    padding-top: 10vh;
  }

  &.mountain-man-slide {
    padding-top: 45vh;
  }

  &.waterman-slide {
    padding-top: 10vh;
  }
}