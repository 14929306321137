body {
  margin: 0;
  padding: 0;
  font-family: 'M PLUS 1p', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center; }

.ps-nav i {
  color: rgba(0, 0, 0, 0.5); }
  .ps-nav i:hover {
    color: black; }

.slider {
  overflow-y: scroll;
  outline: none; }
  .slider .hidden {
    transition: opacity 1s ease-out;
    opacity: 0;
    height: 0;
    overflow: hidden; }
  .slider .visible {
    opacity: 1;
    height: auto; }

.slide {
  min-height: 94vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.home-slides {
  color: aliceblue;
  padding-top: 25vh; }
  .home-slides.summary-slide {
    padding-top: 30vh; }
  .home-slides.software-eng-slide {
    padding-top: 15vh; }
  .home-slides.product-manager-slide {
    padding-top: 10vh; }
  .home-slides.mountain-man-slide {
    padding-top: 45vh; }
  .home-slides.waterman-slide {
    padding-top: 10vh; }

.contact {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: linear-gradient(rgba(0, 47, 75, 0.6), rgba(220, 66, 37, 0.6)); }
  .contact .title {
    padding-bottom: 3rem; }
  .contact a {
    color: black;
    font-size: 3rem; }
    .contact a:hover {
      color: white; }

